<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <div class="form-box">
                <XForm
                    ref="form"
                    border
                    :model="formModel"
                    :rules="formRules"
                >
                    <FormItem
                        label="旧密码："
                        prop="oldPassword"
                    >
                        <XInput
                            type="password"
                            clear
                            auto-size
                            autocomplete="off"
                            placeholder="请输入"
                            v-model="formModel.oldPassword"
                        />
                    </FormItem>
                    <FormItem
                        label="新密码："
                        prop="password"
                    >
                        <XInput
                            type="password"
                            clear
                            auto-size
                            autocomplete="off"
                            placeholder="请输入"
                            v-model="formModel.password"
                        />
                    </FormItem>
                    <FormItem
                        label="确认密码："
                        prop="checkPassword"
                    >
                        <XInput
                            type="password"
                            clear
                            auto-size
                            autocomplete="off"
                            placeholder="请输入"
                            v-model="formModel.checkPassword"
                        />
                    </FormItem>
                </XForm>

                <XButton
                    class="submit"
                    theme="main fill"
                    radius
                    @click="()=>{onSubmit()}"
                >
                    提交
                </XButton>
            </div>
        </container>
    </Page>
</template>

<script>
import { pageMixin } from 'mixins/index';
import { Valid } from '@/utils';

export default {
    name: 'ChangePassword',
    mixins: [pageMixin],
    data() {
        return {
            formModel: {
                oldPassword: this.$globalVar.isDev ? '123456' : '',
                password: this.$globalVar.isDev ? '123456abc' : '',
                checkPassword: this.$globalVar.isDev ? '123456abc' : '',
            },
            formRules: {
                oldPassword: {
                    required: true,
                    message: '旧密码不能为空',
                },
                password: {
                    required: true,
                    message: '新密码不能为空',
                },
                checkPassword: [
                    {
                        required: true,
                        message: '确认密码不能为空',
                    },
                    {
                        validator: Valid.againPassword.bind(this),
                        message: '两处密码不相等',
                    },
                ],
            },
        };
    },
    methods: {
        async onSubmit() {
            await this.$refs.form.validate();
            const { msg } = await this.$api.Comm.Rs.User.modPwd(this.formModel);
            await this.$router.back();
            this.$messageBox.tips(msg);
        },
    },
};
</script>

<style lang="scss">
.form-box {
    padding: $padding-big;
}

.submit {
    min-height: 0.9rem;
    margin-top: 0.5rem;
    width: 100%;
}
</style>
