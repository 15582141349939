<!-- Created by henian.xu on 2019/3/8. -->

<template>
    <Page>
        <Container>
            <div class="form-box">
                <XForm
                    ref="form"
                    border
                    :model="formModel"
                    :rules="formRules"
                >
                    <!--<FormItem
                        v-show="countryCodeShow"
                        prop="countryCodeId"
                        label="国家/地区:"
                    >
                        <CountryCodeSelect
                            ref="CountryCode"
                            @show="onCountryCodeShow"
                            v-model="formModel.countryCodeId"
                        />
                    </FormItem>-->
                    <FormItem
                        label="手机:"
                        prop="account"
                        ref="account"
                    >
                        <XInput v-model="formModel.account" />
                    </FormItem>
                    <FormItem
                        label="密码："
                        prop="password"
                    >
                        <XInput
                            type="password"
                            clear
                            auto-size
                            placeholder="请输入"
                            v-model="formModel.password"
                        />
                    </FormItem>
                    <FormItem
                        label="确认密码："
                        prop="password2"
                    >
                        <XInput
                            type="password"
                            clear
                            auto-size
                            placeholder="请输入"
                            v-model="formModel.password2"
                        />
                    </FormItem>
                    <FormItem
                        label="图片验证码: "
                        prop="jCaptcha"
                        ref="jCaptcha"
                    >
                        <XInput
                            v-model="formModel.jCaptcha"
                        />
                        <div
                            class="authCode"
                            @click="onAuthCodeChange"
                        >
                            <img
                                :src="captchaSrc"
                                width="100%"
                                height="100%"
                            >
                        </div>
                    </FormItem>
                    <FormItem
                        label="验证码："
                        prop="verificationCode"
                    >
                        <XInput
                            auto-size
                            placeholder="请输入"
                            v-model="formModel.verificationCode"
                        />
                        <XButton
                            v-if="!countDown"
                            class="bor-l"
                            size="small"
                            lock-text
                            label="获取验证码"
                            @click="onValidateCode"
                        />
                        <XButton
                            v-else
                            class="bor-l"
                            size="small"
                            lock-text
                            theme="g4"
                            plain
                            :label="`重新获取(${countDown}秒)`"
                        />
                    </FormItem>
                </XForm>


                <XButton
                    class="ma-tb"
                    theme="main"
                    radius="all"
                    size="big"
                    fill
                    lock-text
                    @click="onSubmit"
                >
                    提交
                </XButton>

                <div class="footer-ctrl">
                    <Linker
                        replace
                        :to="`./login?backUrl=${rawBackUrl}`"
                    >
                        已有账号
                    </Linker>
                    <!--<div></div>-->
                    <Linker
                        replace
                        :to="`./forgotPassword?backUrl=${rawBackUrl}`"
                    >
                        忘记密码
                    </Linker>
                </div>
            </div>
        </Container>
    </Page>
</template>

<script>
import { pageMixin, backUrlMixin } from 'mixins/index';

export default {
    name: 'BuyerRegister',
    mixins: [pageMixin, backUrlMixin],
    data() {
        return {
            countDown: 0,
            countDownTimer: 0,
            countryCodeShow: false,
            formModel: {
                countryCodeId: '',
                account: this.$globalVar.isDev ? '18060082532' : '',
                password: this.$globalVar.isDev ? '123456' : '',
                password2: this.$globalVar.isDev ? '123456' : '',
                jCaptcha: '',
                verificationCode: '',
                storeUserName: '',
            },
            formRules: {
                account: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                    /* {
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    }, */
                    {
                        validator: this.checkAccount,
                        // 远程验证 message 远程返回
                    },
                ],
                countryCodeId: {
                    required: true,
                    message: '区号不能为空',
                },
                password: {
                    required: true,
                    message: '密码不能为空',
                },
                password2: {
                    validator: this.$utils.Valid.againPassword.bind(this),
                    message: '两处密码不相等',
                },
                jCaptcha: {
                    required: true,
                    message: '图片验证码不能为空',
                },
                verificationCode: {
                    required: true,
                    message: '短信验证码不能为空',
                },
            },
            captchaSrc: `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/captcha/get-captcha`,
        };
    },
    methods: {
        onAuthCodeChange() {
            this.captchaSrc = `${
                this.$globalVar.isDev ? this.$globalVar.appConfig.AXIOS_DEFAULT_CONFIG.baseURL : '/rpc'
            }/comm/rs/captcha/get-captcha?_T=${Date.now()}`;
        },
        async checkAccount(rule, value, callback) {
            const res = await this.$api.Comm.Rs.User.checkAccount({
                countryCodeId: this.formModel.countryCodeId,
                account: this.formModel.account,
            });
            if (res.success) {
                callback();
            } else {
                callback(new Error(res.msg));
            }
        },
        startCountDown(val) {
            this.countDown = val;
            clearInterval(this.countDownTimer);
            this.countDownTimer = setInterval(() => {
                this.countDown -= 1;
                if (this.countDown <= 0) {
                    this.onAuthCodeChange();
                    clearInterval(this.countDownTimer);
                    this.countDownTimer = 0;
                }
            }, 1000);
        },
        onValidateCode() {
            this.$refs.form.clearValidate();
            Promise.all([this.$refs.account.validate(), this.$refs.jCaptcha.validate()]).then(() => {
                this.$api.Comm.Rs.Sms.sendVerificationCode(this.formModel).then(() => {
                    this.startCountDown(60);
                });
            });
        },
        onSubmit() {
            const self = this;
            const reg = /.*[sS]tore\/(.*)\/.*/;
            let storeUserName = '';
            const { backUrl = '' } = this;
            if (backUrl.match(reg)) {
                storeUserName = (backUrl || '').replace(/.*[sS]tore\/(.*)\/.*/, '$1');
            }
            this.$refs.form.validate().then(() => {
                const params = {
                    ...this.formModel,
                    storeUserName,
                };
                this.$api.Comm.Rs.User.register(params).then(res => {
                    this.$messageBox.tips(res.msg, {
                        callback() {
                            self.$globalVar.routerEdReplace = true;
                            self.$router.replace(`${backUrl || '/'}`);
                        },
                    });
                });
            });
        },
        onCountryCodeShow(val) {
            this.countryCodeShow = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.form-box {
    padding: $padding-big;
}
.authCode {
    width: 200px;
    height: $formItemHeight;
    line-height: 0;
}

.footer-ctrl {
    padding: $padding $padding-big;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    //font-size: $small * 100%;
}
</style>
